"use client";
import React, { createContext, useContext, useState, useEffect } from 'react';

interface PWAContextType {
  isInstallable: boolean;
  handleInstallClick: () => void;
  subscribeToPush: () => Promise<void>;
  isPWA: boolean;
  deviceType: string;
}

const PWAContext = createContext<PWAContextType | undefined>(undefined);

export const usePWA = () => {
    const context = useContext(PWAContext);
    if (context === undefined) {
      throw new Error('usePWA must be used within a PWAProvider');
    }
    return context;
};

type PWAProviderProps = {
  children: React.ReactNode;
};

export const PWAProvider: React.FC<PWAProviderProps> = ({ children }) => {
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [isPWA, setIsPWA] = useState(false);
    const [deviceType, setDeviceType] = useState('unknown');
  
    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
          e.preventDefault();
          setDeferredPrompt(e);
          setIsInstallable(true);
        };
    
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        const userAgent = navigator.userAgent.toLowerCase();
        const urlParams = new URLSearchParams(window.location.search);
        const isPWA = urlParams.get('utm_source') === 'web_app_manifest';
    
        let deviceType = 'unknown';
        if (userAgent) {
          if (/mobile|android|iphone/.test(userAgent)) {
            deviceType = 'mobile';
          } else if (/ipad|tablet/.test(userAgent)) {
            deviceType = 'tablet';
          } else {
            deviceType = 'desktop';
          }
        }
    
        setIsPWA(isPWA);
        setDeviceType(deviceType);
    
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);
  
    const handleInstallClick = async () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsInstallable(false);
      }
    };
  
    const subscribeToPush = async () => {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
          const registration = await navigator.serviceWorker.ready;
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'YOUR_PUBLIC_VAPID_KEY' // Remplacez par votre clé publique VAPID
          });
          console.log('Push subscription:', subscription);
          // Envoyez la souscription au serveur
        } catch (error) {
          console.error('Error subscribing to push notifications:', error);
        }
      } else {
        console.error('Push messaging is not supported');
      }
    };
  
    return (
      <PWAContext.Provider value={{ isInstallable, handleInstallClick, subscribeToPush, isPWA, deviceType }}>
        {children}
      </PWAContext.Provider>
    );
};

