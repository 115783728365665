'use client';
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  FunctionComponent,
} from 'react';
import { getCookie, setCookie, removeCookie } from '@/utils/cookieUtils';

type CookieConsentContextType = {
  consentGiven: boolean;
  giveConsent: () => void;
  revokeConsent: () => void;
};

const CookieConsentContext = createContext<
  CookieConsentContextType | undefined
>(undefined);

export const useCookieConsent = (): CookieConsentContextType => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider'
    );
  }
  return context;
};

interface CookieConsentProviderProps {
  children: ReactNode;
}

export const CookieConsentProvider: FunctionComponent<
  CookieConsentProviderProps
> = ({ children }) => {
  const [consentGiven, setConsentGiven] = useState<boolean>(false);

  useEffect(() => {
    const consent = getCookie('cookieConsent');
    if (consent === 'true') {
      setConsentGiven(true);
    }
  }, []);

  const giveConsent = () => {
    setCookie('cookieConsent', 'true', 365); // Set for 1 year
    setConsentGiven(true);
  };

  const revokeConsent = () => {
    removeCookie('cookieConsent');
    setConsentGiven(false);
  };

  return (
    <CookieConsentContext.Provider
      value={{ consentGiven, giveConsent, revokeConsent }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};
