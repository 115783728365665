"use client";
import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

type NavContextType = {
  activeSection: string;
  updateActiveSection: (section: string) => void;
};

const NavContext = createContext<NavContextType | undefined>(undefined);

export const useNav = () => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error('useNav must be used within a NavProvider');
  }
  return context;
};

type NavProviderProps = {
  children: ReactNode;
};

export const NavProvider: React.FC<NavProviderProps> = ({ children }) => {
  const [activeSection, setActiveSection] = useState<string>('');

  const updateActiveSection = useCallback((section: string) => {
    setActiveSection(section);
  }, []);

  return (
    <NavContext.Provider value={{ activeSection, updateActiveSection }}>
      {children}
    </NavContext.Provider>
  );
};