'use client';
import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { ModeToggle } from '@/components/ui/mode-toggle';
import { RiCloseLine, RiDiscordLine, RiMenuFill } from 'react-icons/ri';
import { Button } from '@/components/ui/button';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useNav } from '@/context/NavContext';
import { usePWA } from '@/context/PWAContext';
import { FaInstagram, FaTelegramPlane, FaTwitter } from 'react-icons/fa';

export const linkClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  section: string
) => {
  e.preventDefault();
  if (window !== undefined) {
    const yOffset = -85; // décalage de 80px
    const element = document.getElementById(section.replace('#', ''));
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
};

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const {
    isInstallable,
    handleInstallClick,
    subscribeToPush,
    isPWA,
    deviceType,
  } = usePWA();
  const { activeSection, updateActiveSection } = useNav();
  const [isSticky, setIsSticky] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  // console.log('isPWA:', isPWA, '| deviceType:', deviceType);
  const pathname = usePathname();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, section: string) => {
      e.preventDefault();
      updateActiveSection(section);
      if (window) {
        linkClick(e, section);
        setCurrentPath(section);
      }
      setIsSidebarOpen(false);
    },
    [updateActiveSection]
  );

  const className = (selected: boolean) => {
    return (
      'transition-all duration-300 font-semibold hover:text-border' +
      (selected ? ' text-border scale-110' : ' text-foreground') +
      (isSticky ? ' text-[16px]' : ' text-lg')
    );
  };

  useEffect(() => {
    // IF Page Home éléments du menu sont affichés
    if (pathname === '/') {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (window === undefined) return;
    // Construire l'URL actuelle avec le chemin, les paramètres de recherche et le hash
    const currentUrl = window?.location.hash;
    if (currentUrl) {
      handleLinkClick(
        new MouseEvent(
          'click'
        ) as unknown as React.MouseEvent<HTMLAnchorElement>,
        currentUrl
      );
    }
  }, [handleLinkClick]);

  useEffect(() => {
    if (activeSection !== currentPath) {
      setCurrentPath(activeSection);
    }
  }, [activeSection, currentPath]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const elements = document.querySelectorAll('[id]');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentPath(entry.target.id);
          updateActiveSection(entry.target.id);
        }
      });
    }, options);

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [updateActiveSection]);

  // Fonction pour vérifier si un lien est actif
  const isActive = (path: string) => {
    return currentPath.includes(path);
  };

  return (
    <>
      <nav
        className={`transition-all duration-300 ${
          isSticky ? 'sticky top-[-1px] bg-background shadow-2xl' : 'relative'
        } z-50 py-4 text-center`}
      >
        <div className="mx-6 lg:container xl:max-w-container-lg lg:mx-auto xl:mx-auto 2xl:mx-auto">
          <div className="flex justify-between items-center gap-2">
            <Link href="/" className="max-w-[150px] max-h-[43px]">
              <Image
                src="/logo.webp"
                alt="logo"
                loading="lazy"
                width={384}
                height={109}
                className="block dark:hidden"
              />
              <Image
                src="/logo_white.webp"
                alt="logo"
                loading="lazy"
                width={384}
                height={109}
                className="hidden dark:block"
              />
            </Link>
            {isHomePage && (
              <div className="hidden md:flex gap-4">
                <Button
                  variant="link"
                  title="Home"
                  asChild
                  className={className(isActive('home'))}
                >
                  <Link
                    href="#home"
                    onClick={(e) => handleLinkClick(e, 'home')}
                  >
                    Accueil
                  </Link>
                </Button>
                <Button
                  variant="link"
                  title="About"
                  className={className(isActive('about'))}
                  asChild
                >
                  <Link
                    href="#about"
                    onClick={(e) => handleLinkClick(e, 'about')}
                  >
                    A propos
                  </Link>
                </Button>
                <Button
                  variant="link"
                  title="Join us"
                  className={className(isActive('join-us'))}
                  asChild
                >
                  <Link
                    href="#join-us"
                    onClick={(e) => handleLinkClick(e, 'join-us')}
                  >
                    Nous rejoindre
                  </Link>
                </Button>
                <Button
                  variant="link"
                  title="Team"
                  className={className(isActive('actualites'))}
                  asChild
                >
                  <Link
                    href="#actualites"
                    onClick={(e) => handleLinkClick(e, 'actualites')}
                  >
                    Actualités
                  </Link>
                </Button>
              </div>
            )}

            <div className="flex gap-4 items-center">
              <Button
                title="Download app"
                className="hidden md:flex items-center bg-[#5F0F90] hover:bg-[#5F0F90] rounded-full text-white transition shadow-xl px-6 hover:scale-105"
                asChild
              >
                <Link
                  href="#join-us"
                  onClick={(e) => handleLinkClick(e, 'join-us')}
                  className="font-bold text-xl tracking-wider"
                >
                  Rejoignez-nous
                </Link>
              </Button>
              <ModeToggle />
              <Button
                variant="ghost"
                className="md:hidden"
                onClick={toggleSidebar}
                aria-label="Toggle menu"
              >
                <RiMenuFill size={24} />
              </Button>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar for mobile */}
      <div
        className={`fixed top-0 right-0 h-full w-60 bg-background shadow-xl transform transition-transform duration-300 ease-in-out z-50 ${
          isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full pt-4">
          <Button
            variant="ghost"
            className="absolute top-0 right-0"
            onClick={toggleSidebar}
            aria-label="Close menu"
          >
            <RiCloseLine size={24} />
          </Button>
          <div className="flex flex-col gap-4">
            <Button
              variant="link"
              title="Home"
              asChild
              className={className(isActive('home'))}
            >
              <Link href="#home" onClick={(e) => handleLinkClick(e, 'home')}>
                Accueil
              </Link>
            </Button>
            {isHomePage && (
              <>
                <Button
                  variant="link"
                  title="About"
                  className={className(isActive('about'))}
                  asChild
                >
                  <Link
                    href="#about"
                    onClick={(e) => handleLinkClick(e, 'about')}
                  >
                    A propos
                  </Link>
                </Button>
                <Button
                  variant="link"
                  title="Join us"
                  className={className(isActive('join-us'))}
                  asChild
                >
                  <Link
                    href="#join-us"
                    onClick={(e) => handleLinkClick(e, 'join-us')}
                  >
                    Nous rejoindre
                  </Link>
                </Button>
                <Button
                  variant="link"
                  title="Team"
                  className={className(isActive('actualites'))}
                  asChild
                >
                  <Link
                    href="#actualites"
                    onClick={(e) => handleLinkClick(e, 'actualites')}
                  >
                    Actualités
                  </Link>
                </Button>
              </>
            )}
          </div>
          <div className="mt-auto m-4">
            <Button
              title="Download app"
              className="w-full rounded-full text-white transition shadow-xl bg-gradient-to-b from-tertiary to-tertiary-foreground px-6 hover:scale-105 drop-shadow-md"
              asChild
            >
              <Link
                href="#join-us"
                onClick={(e) => handleLinkClick(e, 'join-us')}
                className="font-bold text-xl tracking-wider"
              >
                Rejoignez-nous
              </Link>
            </Button>
            <div className="flex justify-center space-x-4 text-purple-800 mt-6">
              <Link
                href="#"
                className="hover:text-purple-600"
                aria-label="Discord"
              >
                <RiDiscordLine size={32} />
              </Link>
              <Link
                href="#"
                className="hover:text-purple-600"
                aria-label="Telegram"
              >
                <FaTelegramPlane size={32} />
              </Link>
              <Link
                href="#"
                className="hover:text-purple-600"
                aria-label="Twitter"
              >
                <FaTwitter size={32} />
              </Link>
              <Link
                href="#"
                className="hover:text-purple-600"
                aria-label="Instagram"
              >
                <FaInstagram size={32} />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Navbar;
