import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import he from 'he';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Encode text with HTML entities
export const encodeHtmlEntities = (str: string): string => {
  return he.decode(str);
};
export const splitLines = (str: string) => {
  return str.split('\n');
};